import { ORDER_ASC, ORDER_DESC, USER_CURRENT_LANG } from "./dataType"

export const libelleComponentSort = (order, source, match, field) => {
    return [...source].sort((b,a) => {
      let ma = match.find(i => i.uid === a[field])
      let mb = match.find(i => i.uid === b[field])
      if(order === ORDER_ASC) return ma.libelle.localeCompare(mb.libelle, USER_CURRENT_LANG)
      if(order === ORDER_DESC) return mb.libelle.localeCompare(ma.libelle, USER_CURRENT_LANG) 
    })
  }

  export const fullnameComponentSort = (order, source, match, field) => {
    return [...source].sort((b,a) => {
      let ma = match.find(i => i.uid === a[field])
      let mb = match.find(i => i.uid === b[field])
      if(order === ORDER_ASC) return ma.fullname.localeCompare(mb.fullname, USER_CURRENT_LANG)
      if(order === ORDER_DESC) return mb.fullname.localeCompare(ma.fullname, USER_CURRENT_LANG) 
    })
  }